<template>
  <div>
    <!-- Or manually control the data synchronization -->
    <codemirror
        ref="cmEditor"
        :value="code"
        :options="cmOptions"
        @input="onCmCodeChange"
    /> 
  </div>
</template>

<script>
    import "codemirror/mode/javascript/javascript.js";
    import "codemirror/theme/base16-dark.css";
    import { codemirror } from 'vue-codemirror';

    // import more 'codemirror/some-resource...'

    export default {
        name: 'CodeEditor',
        components: {
            codemirror
        },
        props: [
            "code",
        ],
        data() {
            return {
                cmOptions: {
                    tabSize: 4,
                    mode: "text/javascript",
                    theme: "base16-dark",
                    lineNumbers: true,
                    line: true
                },
            };
        },
        methods: {
            onCmCodeChange(newCode) {
                this.$emit("cm-onchange", newCode);
            },
        },
        computed: {
            codemirror() {
                return this.$refs.cmEditor.codemirror;
            },
        }
    };
</script>