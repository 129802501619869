export default class WorkerHelper 
{
    constructor(scriptPath) 
    {
        let _this = this;
        this.worker = new Worker(scriptPath);
        this.events = {};

        this.worker.addEventListener('message', function(e) {
            if(Object.prototype.hasOwnProperty.call(_this.events, e.data.event)) {
                _this.events[e.data.event].call(null, e.data.data);
            }
        }, false);

        this.worker.addEventListener('error', function(e) {
            if(Object.prototype.hasOwnProperty.call(_this.events, "error")) {
                _this.events["error"].call(null, e);
            }
        }, false);
    }

    triggerEvent(identifier, data)
    {
        this.worker.postMessage({
            "identifier": identifier, 
            "data": data
        });
    }

    on(identifier, callback)
    {
        this.events[identifier] = callback;
    }
}