<template>
    <v-container>
        <v-card color="basil">
            <v-card-title class="text-center justify-center py-6">
                <v-row class="text-center">
                    <v-col
                        class="mb-4"
                        cols="12"
                        md="6"
                    >
                        <h3 class="font-weight-bold mb-3">
                            Welcome to JavaScript Obfuscator
                        </h3>
                        <p class="subheading font-weight-regular">
                            A free online tool to protect your JavaScript code using the awesome <br> <a href="https://github.com/javascript-obfuscator/javascript-obfuscator" target="_blank">javascript-obfuscator</a> library (<code>javascript-obfuscator@<span>{{obfuscatorVersion}}</span></code>)
                        </p>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                    >
                        <div style="padding-bottom: 15px;">
                            <a href="https://www.digitalocean.com/?refcode=b84458bbcb69&amp;utm_campaign=Referral_Invite&amp;utm_medium=Referral_Program&amp;utm_source=badge" target="_blank">
                                <img
                                    style="width:100%;"
                                    :src="require('../assets/digital-ocean-banner.png')"
                                >
                            </a>
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <!-- Tabs container -->
            <v-tabs
                v-model="currentTab"
                background-color="transparent"
                grow
            >
                <v-tab :key="tabs.tabinput">
                    Your JavaScript Code
                </v-tab>

                <v-tab :key="tabs.tabinput">
                    Batch Obfuscation
                </v-tab>

                <v-tab :key="tabs.taboutput">
                    Your Obfuscated Output
                </v-tab>
            </v-tabs>

            <!-- Tabs Content Container -->
            <v-tabs-items v-model="currentTab"> 
                <v-tab-item :key="tabs.tabinput">
                    <v-card flat>
                        <CodeEditor
                            v-bind:code="code"
                            @cm-onchange="onCodeChanged"
                        />

                        <div class="text-center mt-5 mb-5">
                            <div>
                                <v-btn
                                    @click="obfuscate"
                                    rounded
                                    color="primary"
                                    dark
                                    :loading="isObfuscating"
                                >
                                    Obfuscate
                                </v-btn>
                                &nbsp;
                                <v-btn
                                    color="secondary"
                                    rounded
                                    dark
                                    :loading="isSelecting"
                                    @click="handleFileImport"
                                >
                                    Import local file
                                </v-btn>

                                <input
                                    ref="uploader"
                                    class="d-none"
                                    type="file"
                                    accept="text/*"
                                    @change="onFileChanged"
                                >
                            </div>
                        </div>
                    </v-card>
                </v-tab-item>

                <v-tab-item :key="tabs.tabbatch">
                    <v-card flat>
                        <div class="text-center mt-5 mb-5">
                            <p class="subheading font-weight-regular">
                                Select multiple files and obfuscate them separately.
                            </p>

                            <v-row no-gutters>
                                <v-col
                                    md="6"
                                    offset-md="3"
                                >
                                      <v-file-input
                                v-model="files"
                                counter
                                label="JavaScript Files"
                                multiple
                                accept="text/*"
                                placeholder="Select multiple JavaScript files to obfuscate into a single one"
                                prepend-icon="mdi-paperclip"
                                outlined
                                :show-size="1000"
                            >
                                <template v-slot:selection="{ index, text }">
                                <v-chip
                                    v-if="index < 2"
                                    color="deep-purple accent-4"
                                    dark
                                    label
                                    small
                                >
                                    {{ text }}
                                </v-chip>

                                <span
                                    v-else-if="index === 2"
                                    class="overline grey--text text--darken-3 mx-2"
                                >
                                    +{{ files.length - 2 }} File(s)
                                </span>
                                </template>
                            </v-file-input>
                                </v-col>
                            </v-row>

                           


                            <v-card
                                max-width="600"
                                class="mx-auto"
                            >
                                <v-list>
                                    <v-subheader inset>Files</v-subheader>
                                
                                    <v-list-item
                                        v-for="(file, index) in files"
                                        :key="file.title"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="file.name"></v-list-item-title>
                                            <v-list-item-subtitle v-text="`(${file.size} bytes)`"></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-btn
                                                @click="downloadBatchFile(index)"
                                                rounded
                                                color="primary"
                                                dark
                                                :loading="isObfuscatingBatch"
                                            >
                                                <v-icon color="grey lighten-1">mdi-file-download</v-icon> Download Obfuscated Version
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </div>
                    </v-card>
                </v-tab-item>

                <v-tab-item :key="tabs.taboutput" :eager="true">
                    <v-card flat>
                        <OutputCodeEditor
                            v-bind:code="obfuscatedCode"
                            @cm-onchange="onObfuscatedCodeChanged"
                        />

                        <div class="text-center mt-5 mb-5">
                            <v-btn
                                rounded
                                color="primary"
                                dark
                                v-clipboard:copy="obfuscatedCode"
                                v-clipboard:error="onCopyError"
                            >
                                Copy to Clipboard
                            </v-btn>
                            &nbsp;
                            <v-btn
                                rounded
                                color="default"
                                dark
                                @click="downloadObfuscatedCode"
                            >
                                Download obfuscated code
                            </v-btn>

                            <br>
                            <v-text-field
                                class="mx-auto"
                                style="width: 300px;"
                                label="Output Filename"
                                v-model="outputFilename"
                            ></v-text-field>
                        </div>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        

        <v-expansion-panels focusable class="mt-3">
            <v-expansion-panel :key="1">
            <v-expansion-panel-header>Obfuscation Settings</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form >
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="12"
                                >
                                    <p>Select quickly a default obfuscation preset to define the level of obfuscation:</p>
                                    <v-select
                                        :items="obfuscationSettings.presets"
                                        label="Obfuscation Level"
                                        outlined
                                        @change="changePreset"
                                    ></v-select>
                                    <p>Or well, configure the obfuscation properties by yourself:</p>
                                    <hr>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-select
                                        :label="the_configuration.target.label"
                                        :items="the_configuration.target.values"
                                        v-model="obfuscationSettings.settings.target"
                                        required
                                    ></v-select>
                                    <v-text-field
                                        :label="the_configuration.seed.label"
                                        v-model="obfuscationSettings.settings.seed"
                                        hide-details="auto"
                                    ></v-text-field>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.disableConsoleOutput"
                                        :label="the_configuration.disableConsoleOutput.label"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.selfDefending"
                                        :label="the_configuration.selfDefending.label"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.debugProtection"
                                        :label="the_configuration.debugProtection.label"
                                    ></v-switch>

                                    <v-text-field
                                        :label="the_configuration.debugProtectionInterval.label"
                                        v-model="obfuscationSettings.settings.debugProtectionInterval"
                                        hide-details="auto"
                                    ></v-text-field>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.ignoreRequireImports"
                                        :label="the_configuration.ignoreRequireImports.label"
                                    ></v-switch>




                                    <v-combobox
                                        v-model="obfuscationSettings.settings.domainLock"
                                        chips
                                        clearable
                                        :label="`${the_configuration.domainLock.label} (e.g domain.com)`"
                                        multiple
                                        prepend-icon="mdi-filter-variant"
                                        solo
                                    >
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click="select"
                                            @click:close="remove(item)"
                                        >
                                            <strong>{{ item }}</strong>&nbsp;
                                            <span>(use backspace to remove)</span>
                                        </v-chip>
                                        </template>
                                    </v-combobox>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.sourceMap"
                                        :label="the_configuration.sourceMap.label"
                                    ></v-switch>


                                    <v-select
                                        :label="the_configuration.sourceMapMode.label"
                                        :items="the_configuration.sourceMapMode.values"
                                        v-model="obfuscationSettings.settings.sourceMapMode"
                                        :disabled="obfuscationSettings.settings.sourceMap ? false : true"
                                    ></v-select>

                                    <v-text-field
                                        :label="the_configuration.sourceMapBaseUrl.label"
                                        v-model="obfuscationSettings.settings.sourceMapBaseUrl"
                                        :disabled="obfuscationSettings.settings.sourceMap ? false : true"
                                    ></v-text-field>

                                    <v-text-field
                                        :label="the_configuration.sourceMapFileName.label"
                                        v-model="obfuscationSettings.settings.sourceMapFileName"
                                        :disabled="obfuscationSettings.settings.sourceMap ? false : true"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-switch
                                        v-model="obfuscationSettings.settings.stringArray"
                                        :label="the_configuration.stringArray.label"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.rotateStringArray"
                                        :label="the_configuration.rotateStringArray.label"
                                        :disabled="obfuscationSettings.settings.stringArray ? false : true"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.shuffleStringArray"
                                        :label="the_configuration.shuffleStringArrayEnabled.label"
                                        :disabled="obfuscationSettings.settings.stringArray ? false : true"
                                    ></v-switch>

                                    <v-text-field
                                        :label="the_configuration.stringArrayThreshold.label"
                                        v-model="obfuscationSettings.settings.stringArrayThreshold"
                                        :disabled="obfuscationSettings.settings.stringArray ? false : true"
                                    ></v-text-field>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.stringArrayIndexShift"
                                        :label="the_configuration.stringArrayIndexShift.label"
                                        :disabled="obfuscationSettings.settings.stringArray ? false : true"
                                    ></v-switch>

                                    <v-select
                                        v-model="obfuscationSettings.settings.stringArrayIndexesType"
                                        :items="the_configuration.stringArrayIndexesType.values"
                                        :label="the_configuration.stringArrayIndexesType.label"
                                        multiple
                                        persistent-hint
                                    ></v-select>

                                    <v-text-field
                                        :label="the_configuration.stringArrayWrappersCount.label"
                                        v-model="obfuscationSettings.settings.stringArrayWrappersCount"
                                        :disabled="obfuscationSettings.settings.stringArray ? false : true"
                                    ></v-text-field>

                                    <v-select
                                        :label="the_configuration.stringArrayWrappersType.label"
                                        :items="the_configuration.stringArrayWrappersType.values"
                                        v-model="obfuscationSettings.settings.stringArrayWrappersType"
                                        :disabled="obfuscationSettings.settings.stringArray ? false : true"
                                    ></v-select>

                                    <v-text-field
                                        :label="the_configuration.stringArrayWrappersParametersMaxCount.label"
                                        v-model="obfuscationSettings.settings.stringArrayWrappersParametersMaxCount"
                                        :disabled="obfuscationSettings.settings.stringArray ? false : true"
                                    ></v-text-field>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.stringArrayWrappersChainedCalls"
                                        :label="the_configuration.stringArrayWrappersChainedCalls.label"
                                        :disabled="obfuscationSettings.settings.stringArray ? false : true"
                                    ></v-switch>

                                    <v-select
                                        v-model="obfuscationSettings.settings.stringArrayEncoding"
                                        :items="the_configuration.stringArrayEncoding.values"
                                        :label="the_configuration.stringArrayEncoding.label"
                                        multiple
                                        persistent-hint
                                    ></v-select>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.splitStrings"
                                        :label="the_configuration.splitStrings.label"
                                    ></v-switch>

                                    <v-text-field
                                        :label="the_configuration.splitStringsChunkLength.label"
                                        v-model="obfuscationSettings.settings.splitStringsChunkLength"
                                        :disabled="obfuscationSettings.settings.splitStrings ? false : true"
                                    ></v-text-field>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.unicodeEscapeSequence"
                                        :label="the_configuration.unicodeEscapeSequence.label"
                                    ></v-switch>


 
                                    <v-combobox
                                        v-model="obfuscationSettings.settings.forceTransformStrings"
                                        chips
                                        clearable
                                        :label="`${the_configuration.forceTransformStrings.label}`"
                                        multiple
                                        prepend-icon="mdi-filter-variant"
                                        solo
                                    >
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click="select"
                                            @click:close="remove(item)"
                                        >
                                            <strong>{{ item }}</strong>&nbsp;
                                            <span>(use backspace to remove)</span>
                                        </v-chip>
                                        </template>
                                    </v-combobox>


                                    <v-combobox
                                        v-model="obfuscationSettings.settings.reservedStrings"
                                        chips
                                        clearable
                                        :label="`${the_configuration.reservedStrings.label}`"
                                        multiple
                                        prepend-icon="mdi-filter-variant"
                                        solo
                                    >
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click="select"
                                            @click:close="remove(item)"
                                        >
                                            <strong>{{ item }}</strong>&nbsp;
                                            <span>(use backspace to remove)</span>
                                        </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select
                                        :label="the_configuration.identifierNamesGenerator.label"
                                        :items="the_configuration.identifierNamesGenerator.values"
                                        v-model="obfuscationSettings.settings.identifierNamesGenerator"
                                        required
                                    ></v-select>

                                    <v-combobox
                                        v-model="obfuscationSettings.settings.identifiersDictionary"
                                        chips
                                        clearable
                                        :label="`${the_configuration.identifiersDictionary.label}`"
                                        multiple
                                        prepend-icon="mdi-filter-variant"
                                        solo
                                    >
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click="select"
                                            @click:close="remove(item)"
                                        >
                                            <strong>{{ item }}</strong>&nbsp;
                                            <span>(use backspace to remove)</span>
                                        </v-chip>
                                        </template>
                                    </v-combobox>

                                    <v-text-field
                                        :label="the_configuration.identifiersPrefix.label"
                                        v-model="obfuscationSettings.settings.identifiersPrefix"
                                    ></v-text-field>


                                    <v-switch
                                        v-model="obfuscationSettings.settings.renameGlobals"
                                        :label="the_configuration.renameGlobals.label"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.renameProperties"
                                        :label="the_configuration.renameProperties.label"
                                    ></v-switch>
 
                                    <v-combobox
                                        v-model="obfuscationSettings.settings.reservedNames"
                                        chips
                                        clearable
                                        :label="`${the_configuration.reservedNames.label}`"
                                        multiple
                                        prepend-icon="mdi-filter-variant"
                                        solo
                                    >
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click="select"
                                            @click:close="remove(item)"
                                        >
                                            <strong>{{ item }}</strong>&nbsp;
                                            <span>(use backspace to remove)</span>
                                        </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-switch
                                        v-model="obfuscationSettings.settings.compact"
                                        :label="the_configuration.compact.label"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.simplify"
                                        :label="the_configuration.simplify.label"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.transformObjectKeys"
                                        :label="the_configuration.transformObjectKeys.label"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.numbersToExpressions"
                                        :label="the_configuration.numbersToExpressions.label"
                                    ></v-switch>

                                    <v-switch
                                        v-model="obfuscationSettings.settings.controlFlowFlattening"
                                        :label="the_configuration.controlFlowFlattening.label"
                                    ></v-switch>

                                    <v-text-field
                                        :label="the_configuration.controlFlowFlatteningThreshold.label"
                                        v-model="obfuscationSettings.settings.controlFlowFlatteningThreshold"
                                        :disabled="obfuscationSettings.settings.controlFlowFlattening ? false : true"
                                    ></v-text-field>
                                    
                                    <v-switch
                                        v-model="obfuscationSettings.settings.deadCodeInjection"
                                        :label="the_configuration.deadCodeInjection.label"
                                    ></v-switch>

                                    <v-text-field
                                        :label="the_configuration.deadCodeInjectionThreshold.label"
                                        v-model="obfuscationSettings.settings.deadCodeInjectionThreshold"
                                        :disabled="obfuscationSettings.settings.deadCodeInjection ? false : true"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <Documentation />

    </v-container>
</template>

<script>
    import Documentation from './Documentation';

    import CodeEditor from './CodeEditor';
    import OutputCodeEditor from './OutputCodeEditor';
    import WorkerHelper from './../WorkerHelper'; 
    import Configuration from './configuration.js';
    import { saveAs } from 'file-saver';

    const ObfuscatorWorker = new WorkerHelper("/workers/obfuscator.worker.js");

    export default {
        name: 'Application',
        components: {
            CodeEditor, 
            OutputCodeEditor,
            Documentation
        },
        data() {
            return {
                files: [],
                the_configuration: Configuration,
                code: `const myWebsite = "https://ourcodeworld.com"`,
                obfuscatedCode: "",
                isSelecting: false,
                isObfuscating: false,
                isObfuscatingBatch: false,
                currentTab: null,
                outputFilename: "obfuscated-output.js",
                obfuscatorVersion: "",
                tabs: [
                    'tabinput', 
                    'tabbatch',
                    'taboutput',
                ],
                obfuscationSettings: {
                    presets: [
                        "Default Obfuscation",
                        "Low Obfuscation",
                        "Medium Obfuscation",
                        "High Obfuscation"
                    ],
                    settings: {
                        compact: true,
                        controlFlowFlattening: false,
                        controlFlowFlatteningThreshold: 0.75,
                        deadCodeInjection: false,
                        deadCodeInjectionThreshold: 0.4,
                        debugProtection: false,
                        debugProtectionInterval: 0,
                        disableConsoleOutput: false,
                        domainLock: [],
                        domainLockEnabled: true,
                        forceTransformStrings: [],
                        identifierNamesGenerator: "hexadecimal",
                        identifiersDictionary: [],
                        identifiersPrefix: "",
                        ignoreRequireImports: false,
                        numbersToExpressions: false,
                        renameGlobals: false,
                        renameProperties: false,
                        reservedNames: [],
                        reservedStrings: [],
                        rotateStringArray: true,
                        rotateStringArrayEnabled: true,
                        seed: 0,
                        selfDefending: false,
                        shuffleStringArray: true,
                        shuffleStringArrayEnabled: true,
                        simplify: true,
                        sourceMap: false,
                        sourceMapBaseUrl: "",
                        sourceMapFileName: "",
                        sourceMapMode: "separate",
                        splitStrings: false,
                        splitStringsChunkLength: 10,
                        splitStringsChunkLengthEnabled: false,
                        stringArray: true,
                        stringArrayEncoding: ["none"],
                        stringArrayEncodingEnabled: true,
                        stringArrayIndexShift: true,
                        stringArrayIndexesType: ["hexadecimal-number"],
                        stringArrayThreshold: 0.75,
                        stringArrayThresholdEnabled: true,
                        stringArrayWrappersChainedCalls: true,
                        stringArrayWrappersCount: 1,
                        stringArrayWrappersParametersMaxCount: 2,
                        stringArrayWrappersType: "variable",
                        target: "browser",
                        transformObjectKeys: false,
                        unicodeEscapeSequence: false
                    }
                }
            }
        },
        methods: {
            /**
             * Configure the obfuscation preset when the users picks a specific one
             */
            changePreset(preset){
                switch(preset){
                    case "Default Obfuscation":
                        this.obfuscationSettings.settings = {
                            ...this.obfuscationSettings.settings,
                            ...Configuration.presets.default
                        };
                        break;
                    case "Low Obfuscation":
                        this.obfuscationSettings.settings = {
                            ...this.obfuscationSettings.settings,
                            ...Configuration.presets.low
                        };
                        break;
                    case "Medium Obfuscation":
                        this.obfuscationSettings.settings = {
                            ...this.obfuscationSettings.settings,
                            ...Configuration.presets.medium
                        };
                        break;
                    case "High Obfuscation":
                        this.obfuscationSettings.settings = {
                            ...this.obfuscationSettings.settings,
                            ...Configuration.presets.high
                        };
                        break;
                }
            },
            /**
             * Trigger File Selector
             */
            handleFileImport() {
                this.isSelecting = true;

                window.addEventListener('focus', () => {
                    this.isSelecting = false
                }, { once: true });

                this.$refs.uploader.click();
            },
            onFileChanged(e) {
                this.selectedFile = e.target.files[0];

                // Abort if the user doesn't select anything
                if(!this.selectedFile) return;

                let reference = this;

                let fr = new FileReader();

                fr.onload = function(){
                    reference.code = fr.result;
                    reference.outputFilename = `obfuscated-${reference.selectedFile.name}`;
                };
              
                fr.readAsText(this.selectedFile);
            },
            onCodeChanged(newCode){
                this.code = newCode;
            },
            onObfuscatedCodeChanged(newCode){
                this.obfuscatedCode = newCode;
            },
            /**
             * Obfuscate the given code using the Worker
             */
            obfuscate(){
                this.isObfuscating = true;

                ObfuscatorWorker.triggerEvent("obfuscate-code", {
                    'code' : this.code,
                    'settings': this.obfuscationSettings.settings
                });
            },
            /**
             *  When the user clicks on Download Obfuscated Code
             */
            downloadObfuscatedCode(){
                let blob = new Blob([this.obfuscatedCode], {type: "application/javascript;charset=utf-8"});
                saveAs(blob, this.outputFilename);
            },
            onCopyError: function (e) {
                alert('Failed to copy the text to the clipboard');
                console.log(e);
            },
            readFileAsText(file){
                return new Promise(function(resolve,reject){
                    let fr = new FileReader();

                    fr.onload = function(){
                        resolve(fr.result);
                    };

                    fr.onerror = function(){
                        reject(fr);
                    };

                    fr.readAsText(file);
                });
            },
            downloadBatchFile(i){
                let _this = this;
                let file = this.files[i];

                this.isObfuscatingBatch = true;

                this.readFileAsText(file).then(function(content){
                    ObfuscatorWorker.triggerEvent("obfuscate-batch-code", {
                        'code' : content,
                        'settings': _this.obfuscationSettings.settings,
                        'filename': file.name
                    });
                }); 
            }
        },
        mounted() {
            let _this = this;

            ObfuscatorWorker.on("obfuscated-code", function(response){
                _this.obfuscatedCode = response.code;
                _this.currentTab = 2;

                _this.isObfuscating = false;
            });

            ObfuscatorWorker.on("obfuscated-batch-code", function(response){
                _this.isObfuscatingBatch = false;

                let blob = new Blob([response.code], {type: "application/javascript;charset=utf-8"});
                saveAs(blob, response.filename);
            });

            ObfuscatorWorker.on("obfuscated-code", function(response){
                _this.obfuscatedCode = response.code;
                _this.currentTab = 2;

                _this.isObfuscating = false;
            });

            ObfuscatorWorker.on("obtain-version", function(response){
                _this.obfuscatorVersion = response.version;
            });

            ObfuscatorWorker.on("error", function(response){
                console.error(response);
                alert(response.message);
                _this.isObfuscating = false;
            });
 
            ObfuscatorWorker.triggerEvent("get-version");
        }
    }
</script>