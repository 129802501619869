<template>
  <v-expansion-panels focusable class="mt-3">
    <v-expansion-panel :key="1">
      <v-expansion-panel-header> Documentation </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- ##options-start## -->
        <h3 id="-compact-"><code>compact</code></h3>
        <p>Type: <code>boolean</code> Default: <code>true</code></p>
        <p>Compact code output on one line.</p>
        <h3 id="-config-"><code>config</code></h3>
        <p>Type: <code>string</code> Default: ``</p>
        <p>
          Name of JS/JSON config file which contains obfuscator options. These
          will be overridden by options passed directly to CLI
        </p>
        <h3 id="-controlflowflattening-"><code>controlFlowFlattening</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <h5
          id="-warning-this-option-greatly-affects-the-performance-up-to-1-5x-slower-runtime-speed-use-controlflowflatteningthreshold-controlflowflatteningthreshold-to-set-percentage-of-nodes-that-will-affected-by-control-flow-flattening-"
        >
          :warning: This option greatly affects the performance up to 1.5x
          slower runtime speed. Use
          <a href="#controlflowflatteningthreshold"
            ><code>controlFlowFlatteningThreshold</code></a
          >
          to set percentage of nodes that will affected by control flow
          flattening.
        </h5>
        <p>
          Enables code control flow flattening. Control flow flattening is a
          structure transformation of the source code that hinders program
          comprehension.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// input</span>
(<span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params"></span>)</span>{
    <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">foo</span> (<span class="hljs-params"></span>) </span>{
        <span class="hljs-keyword">return</span> <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-keyword">var</span> sum = <span class="hljs-number">1</span> + <span class="hljs-number">2</span>;
            <span class="hljs-built_in">console</span>.log(<span class="hljs-number">1</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-number">2</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-number">3</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-number">4</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-number">5</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-number">6</span>);
        }
    }

    foo()();
})();

<span class="hljs-comment">// output</span>
(<span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
    <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">_0x3bfc5c</span>(<span class="hljs-params"></span>) </span>{
        <span class="hljs-keyword">return</span> <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-keyword">var</span> _0x3260a5 = {
                <span class="hljs-string">'WtABe'</span>: <span class="hljs-string">'4|0|6|5|3|2|1'</span>,
                <span class="hljs-string">'GokKo'</span>: <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">_0xf87260</span>(<span class="hljs-params">_0x427a8e, _0x43354c</span>) </span>{
                    <span class="hljs-keyword">return</span> _0x427a8e + _0x43354c;
                }
            };
            <span class="hljs-keyword">var</span> _0x1ad4d6 = _0x3260a5[<span class="hljs-string">'WtABe'</span>][<span class="hljs-string">'split'</span>](<span class="hljs-string">'|'</span>), _0x1a7b12 = <span class="hljs-number">0x0</span>;
            <span class="hljs-keyword">while</span> (!![]) {
                <span class="hljs-keyword">switch</span> (_0x1ad4d6[_0x1a7b12++]) {
                <span class="hljs-keyword">case</span> <span class="hljs-string">'0'</span>:
                    <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x1</span>);
                    <span class="hljs-keyword">continue</span>;
                <span class="hljs-keyword">case</span> <span class="hljs-string">'1'</span>:
                    <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x6</span>);
                    <span class="hljs-keyword">continue</span>;
                <span class="hljs-keyword">case</span> <span class="hljs-string">'2'</span>:
                    <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x5</span>);
                    <span class="hljs-keyword">continue</span>;
                <span class="hljs-keyword">case</span> <span class="hljs-string">'3'</span>:
                    <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x4</span>);
                    <span class="hljs-keyword">continue</span>;
                <span class="hljs-keyword">case</span> <span class="hljs-string">'4'</span>:
                    <span class="hljs-keyword">var</span> _0x1f2f2f = _0x3260a5[<span class="hljs-string">'GokKo'</span>](<span class="hljs-number">0x1</span>, <span class="hljs-number">0x2</span>);
                    <span class="hljs-keyword">continue</span>;
                <span class="hljs-keyword">case</span> <span class="hljs-string">'5'</span>:
                    <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x3</span>);
                    <span class="hljs-keyword">continue</span>;
                <span class="hljs-keyword">case</span> <span class="hljs-string">'6'</span>:
                    <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x2</span>);
                    <span class="hljs-keyword">continue</span>;
                }
                <span class="hljs-keyword">break</span>;
            }
        };
    }

    _0x3bfc5c()();
}());
</code></pre>
        <h3 id="-controlflowflatteningthreshold-">
          <code>controlFlowFlatteningThreshold</code>
        </h3>
        <p>
          Type: <code>number</code> Default: <code>0.75</code> Min:
          <code>0</code> Max: <code>1</code>
        </p>
        <p>
          The probability that the
          <a href="#controlflowflattening"
            ><code>controlFlowFlattening</code></a
          >
          transformation will be applied to any given node.
        </p>
        <p>
          This setting is especially useful for large code size because large
          amounts of control flow transformations can slow down your code and
          increase code size.
        </p>
        <p>
          <code>controlFlowFlatteningThreshold: 0</code> equals to
          <code>controlFlowFlattening: false</code>.
        </p>
        <h3 id="-deadcodeinjection-"><code>deadCodeInjection</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <h5
          id="-warning-dramatically-increases-size-of-obfuscated-code-up-to-200-use-only-if-size-of-obfuscated-code-doesn-t-matter-use-deadcodeinjectionthreshold-deadcodeinjectionthreshold-to-set-percentage-of-nodes-that-will-affected-by-dead-code-injection-"
        >
          :warning: Dramatically increases size of obfuscated code (up to 200%),
          use only if size of obfuscated code doesn&#39;t matter. Use
          <a href="#deadcodeinjectionthreshold"
            ><code>deadCodeInjectionThreshold</code></a
          >
          to set percentage of nodes that will affected by dead code injection.
        </h5>
        <h5 id="-warning-this-option-forcibly-enables-stringarray-option-">
          :warning: This option forcibly enables
          <code>stringArray</code> option.
        </h5>
        <p>
          With this option, random blocks of dead code will be added to the
          obfuscated code.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// input</span>
(<span class="hljs-function"><span class="hljs-keyword">function</span>(<span class="hljs-params"></span>)</span>{
    <span class="hljs-keyword">if</span> (<span class="hljs-literal">true</span>) {
        <span class="hljs-keyword">var</span> foo = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'abc'</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'cde'</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'efg'</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'hij'</span>);
        };

        <span class="hljs-keyword">var</span> bar = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'klm'</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'nop'</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'qrs'</span>);
        };

        <span class="hljs-keyword">var</span> baz = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'tuv'</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'wxy'</span>);
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'z'</span>);
        };

        foo();
        bar();
        baz();
    }
})();

<span class="hljs-comment">// output</span>
<span class="hljs-keyword">var</span> _0x5024 = [
    <span class="hljs-string">'zaU'</span>,
    <span class="hljs-string">'log'</span>,
    <span class="hljs-string">'tuv'</span>,
    <span class="hljs-string">'wxy'</span>,
    <span class="hljs-string">'abc'</span>,
    <span class="hljs-string">'cde'</span>,
    <span class="hljs-string">'efg'</span>,
    <span class="hljs-string">'hij'</span>,
    <span class="hljs-string">'QhG'</span>,
    <span class="hljs-string">'TeI'</span>,
    <span class="hljs-string">'klm'</span>,
    <span class="hljs-string">'nop'</span>,
    <span class="hljs-string">'qrs'</span>,
    <span class="hljs-string">'bZd'</span>,
    <span class="hljs-string">'HMx'</span>
];
<span class="hljs-keyword">var</span> _0x4502 = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">_0x1254b1, _0x583689</span>) </span>{
    _0x1254b1 = _0x1254b1 - <span class="hljs-number">0x0</span>;
    <span class="hljs-keyword">var</span> _0x529b49 = _0x5024[_0x1254b1];
    <span class="hljs-keyword">return</span> _0x529b49;
};
(<span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">if</span> (!![]) {
        <span class="hljs-keyword">var</span> _0x16c18d = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-keyword">if</span> (_0x4502(<span class="hljs-string">'0x0'</span>) !== _0x4502(<span class="hljs-string">'0x0'</span>)) {
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x2'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x3'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](<span class="hljs-string">'z'</span>);
            } <span class="hljs-keyword">else</span> {
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x4'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x5'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x6'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x7'</span>));
            }
        };
        <span class="hljs-keyword">var</span> _0x1f7292 = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-keyword">if</span> (_0x4502(<span class="hljs-string">'0x8'</span>) === _0x4502(<span class="hljs-string">'0x9'</span>)) {
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0xa'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0xb'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0xc'</span>));
            } <span class="hljs-keyword">else</span> {
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0xa'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0xb'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0xc'</span>));
            }
        };
        <span class="hljs-keyword">var</span> _0x33b212 = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-keyword">if</span> (_0x4502(<span class="hljs-string">'0xd'</span>) !== _0x4502(<span class="hljs-string">'0xe'</span>)) {
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x2'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x3'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](<span class="hljs-string">'z'</span>);
            } <span class="hljs-keyword">else</span> {
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x4'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x5'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x6'</span>));
                <span class="hljs-built_in">console</span>[_0x4502(<span class="hljs-string">'0x1'</span>)](_0x4502(<span class="hljs-string">'0x7'</span>));
            }
        };
        _0x16c18d();
        _0x1f7292();
        _0x33b212();
    }
}());
</code></pre>
        <h3 id="-deadcodeinjectionthreshold-">
          <code>deadCodeInjectionThreshold</code>
        </h3>
        <p>
          Type: <code>number</code> Default: <code>0.4</code> Min:
          <code>0</code> Max: <code>1</code>
        </p>
        <p>
          Allows to set percentage of nodes that will affected by
          <code>deadCodeInjection</code>.
        </p>
        <h3 id="-debugprotection-"><code>debugProtection</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <h5
          id="-warning-can-freeze-your-browser-if-you-open-the-developer-tools-"
        >
          :warning: Can freeze your browser if you open the Developer Tools.
        </h5>
        <p>
          This option makes it almost impossible to use the
          <code>debugger</code> function of the Developer Tools (both on
          WebKit-based and Mozilla Firefox).
        </p>
        <h3 id="-debugprotectioninterval-">
          <code>debugProtectionInterval</code>
        </h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <h5 id="-warning-can-freeze-your-browser-use-at-own-risk-">
          :warning: Can freeze your browser! Use at own risk.
        </h5>
        <p>
          If checked, an interval is used to force the debug mode on the Console
          tab, making it harder to use other features of the Developer Tools.
          Works if
          <a href="#debugprotection"><code>debugProtection</code></a> is
          enabled.
        </p>
        <h3 id="-disableconsoleoutput-"><code>disableConsoleOutput</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <p>
          Disables the use of <code>console.log</code>,
          <code>console.info</code>, <code>console.error</code>,
          <code>console.warn</code>, <code>console.debug</code>,
          <code>console.exception</code> and <code>console.trace</code> by
          replacing them with empty functions. This makes the use of the
          debugger harder.
        </p>
        <h3 id="-domainlock-"><code>domainLock</code></h3>
        <p>Type: <code>string[]</code> Default: <code>[]</code></p>
        <h5 id="-warning-this-option-does-not-work-with-target-node-">
          :warning: This option does not work with
          <code>target: &#39;node&#39;</code>
        </h5>
        <p>
          Allows to run the obfuscated source code only on specific domains
          and/or sub-domains. This makes really hard for someone to just copy
          and paste your source code and run it elsewhere.
        </p>
        <h5 id="multiple-domains-and-sub-domains">
          Multiple domains and sub-domains
        </h5>
        <p>
          It&#39;s possible to lock your code to more than one domain or
          sub-domain. For instance, to lock it so the code only runs on
          <strong>www.example.com</strong> add <code>www.example.com</code>. To
          make it work on the root domain including any sub-domains
          (<code>example.com</code>, <code>sub.example.com</code>), use
          <code>.example.com</code>.
        </p>
        <h3 id="-exclude-"><code>exclude</code></h3>
        <p>Type: <code>string[]</code> Default: <code>[]</code></p>
        <p>
          A file names or globs which indicates files to exclude from
          obfuscation.
        </p>
        <h3 id="-forcetransformstrings-"><code>forceTransformStrings</code></h3>
        <p>Type: <code>string[]</code> Default: <code>[]</code></p>
        <p>
          Enables force transformation of string literals, which being matched
          by passed RegExp patterns.
        </p>
        <h5
          id="-warning-this-option-affects-only-strings-that-shouldn-t-be-transformed-by-stringarraythreshold-stringarraythreshold-or-possible-other-thresholds-in-the-future-"
        >
          :warning: This option affects only strings that shouldn&#39;t be
          transformed by
          <a href="#stringarraythreshold"><code>stringArrayThreshold</code></a>
          (or possible other thresholds in the future)
        </h5>
        <p>
          The option has a priority over <code>reservedStrings</code> option but
          hasn&#39;t a priority over <code>conditional comments</code>.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts">    {
        <span class="hljs-attribute">forceTransformStrings</span>: [
            <span class="hljs-string">'some-important-value'</span>,
            <span class="hljs-string">'some-string_\d'</span>
        ]
    }
</code></pre>
        <h3 id="-identifiernamesgenerator-">
          <code>identifierNamesGenerator</code>
        </h3>
        <p>Type: <code>string</code> Default: <code>hexadecimal</code></p>
        <p>Sets identifier names generator.</p>
        <p>Available values:</p>
        <ul>
          <li>
            <code>dictionary</code>: identifier names from
            <a href="#identifiersdictionary"
              ><code>identifiersDictionary</code></a
            >
            list
          </li>
          <li>
            <code>hexadecimal</code>: identifier names like
            <code>_0xabc123</code>
          </li>
          <li>
            <code>mangled</code>: short identifier names like <code>a</code>,
            <code>b</code>, <code>c</code>
          </li>
          <li>
            <code>mangled-shuffled</code>: same as <code>mangled</code> but with
            shuffled alphabet
          </li>
        </ul>
        <h3 id="-identifiersdictionary-"><code>identifiersDictionary</code></h3>
        <p>Type: <code>string[]</code> Default: <code>[]</code></p>
        <p>
          Sets identifiers dictionary for
          <a href="#identifiernamesgenerator"
            ><code>identifierNamesGenerator</code></a
          >: <code>dictionary</code> option. Each identifier from the dictionary
          will be used in a few variants with a different casing of each
          character. Thus, the number of identifiers in the dictionary should
          depend on the identifiers amount at original source code.
        </p>
        <h3 id="-identifiersprefix-"><code>identifiersPrefix</code></h3>
        <p>Type: <code>string</code> Default: <code>&#39;&#39;</code></p>
        <p>Sets prefix for all global identifiers.</p>
        <p>
          Use this option when you want to obfuscate multiple files. This option
          helps to avoid conflicts between global identifiers of these files.
          Prefix should be different for every file.
        </p>
        <h3 id="-ignorerequireimports-"><code>ignoreRequireImports</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <p>
          Prevents obfuscation of <code>require</code> imports. Could be helpful
          in some cases when for some reason runtime environment requires these
          imports with static strings only.
        </p>
        <h3 id="-inputfilename-"><code>inputFileName</code></h3>
        <p>Type: <code>string</code> Default: <code>&#39;&#39;</code></p>
        <p>
          Allows to set name of the input file with source code. This name will
          be used internally for source map generation.
        </p>
        <h3 id="-log-"><code>log</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <p>Enables logging of the information to the console.</p>
        <h3 id="-numberstoexpressions-"><code>numbersToExpressions</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <p>Enables numbers conversion to expressions</p>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// input</span>
const foo = <span class="hljs-number">1234</span>;

<span class="hljs-comment">// output</span>
const foo=<span class="hljs-number">-0xd93</span>+<span class="hljs-number">-0x10b4</span>+<span class="hljs-number">0x41</span>*<span class="hljs-number">0x67</span>+<span class="hljs-number">0x84e</span>*<span class="hljs-number">0x3</span>+<span class="hljs-number">-0xff8</span>;
</code></pre>
        <h3 id="-optionspreset-"><code>optionsPreset</code></h3>
        <p>Type: <code>string</code> Default: <code>default</code></p>
        <p>Allows to set <a href="#preset-options">options preset</a>.</p>
        <p>Available values:</p>
        <ul>
          <li><code>default</code>;</li>
          <li><code>low-obfuscation</code>;</li>
          <li><code>medium-obfuscation</code>;</li>
          <li><code>high-obfuscation</code>.</li>
        </ul>
        <p>All addition options will be merged with selected options preset.</p>
        <h3 id="-renameglobals-"><code>renameGlobals</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <h5
          id="-warning-this-option-can-break-your-code-enable-it-only-if-you-know-what-it-does-"
        >
          :warning: this option can break your code. Enable it only if you know
          what it does!
        </h5>
        <p>
          Enables obfuscation of global variable and function names
          <strong>with declaration</strong>.
        </p>
        <h3 id="-renameproperties-"><code>renameProperties</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <h5
          id="-warning-this-option-will-break-your-code-in-most-cases-enable-it-only-if-you-know-what-it-does-"
        >
          :warning: this option <strong>WILL</strong> break your code in most
          cases. Enable it only if you know what it does!
        </h5>
        <p>
          Enables renaming of property names. All built-in DOM properties and
          properties in core JavaScript classes will be ignored.
        </p>
        <p>
          To set format of renamed property names use
          <a href="#identifiernamesgenerator"
            ><code>identifierNamesGenerator</code></a
          >
          option.
        </p>
        <p>
          To control which properties will be renamed use
          <a href="#reservednames"><code>reservedNames</code></a> option.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// input</span>
(<span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">const</span> foo = {
        <span class="hljs-attr">prop1</span>: <span class="hljs-number">1</span>,
        <span class="hljs-attr">prop2</span>: <span class="hljs-number">2</span>,
        <span class="hljs-attr">calc</span>: <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-keyword">return</span> <span class="hljs-keyword">this</span>.prop1 + <span class="hljs-keyword">this</span>.prop2;
        }
    };

    <span class="hljs-built_in">console</span>.log(foo.calc());
})();

<span class="hljs-comment">// output</span>
(<span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">const</span> _0x46529b = {
        <span class="hljs-string">'_0x10cec7'</span>: <span class="hljs-number">0x1</span>,
        <span class="hljs-string">'_0xc1c0ca'</span>: <span class="hljs-number">0x2</span>,
        <span class="hljs-string">'_0x4b961d'</span>: <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
            <span class="hljs-keyword">return</span> <span class="hljs-keyword">this</span>[<span class="hljs-string">'_0x10cec7'</span>] + <span class="hljs-keyword">this</span>[<span class="hljs-string">'_0xc1c0ca'</span>];
        }
    };
    <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](_0x46529b[<span class="hljs-string">'_0x4b961d'</span>]());
}());
</code></pre>
        <h3 id="-reservednames-"><code>reservedNames</code></h3>
        <p>Type: <code>string[]</code> Default: <code>[]</code></p>
        <p>
          Disables obfuscation and generation of identifiers, which being
          matched by passed RegExp patterns.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts">    {
        <span class="hljs-attribute">reservedNames</span>: [
            <span class="hljs-string">'^someVariable'</span>,
            <span class="hljs-string">'functionParameter_\d'</span>
        ]
    }
</code></pre>
        <h3 id="-reservedstrings-"><code>reservedStrings</code></h3>
        <p>Type: <code>string[]</code> Default: <code>[]</code></p>
        <p>
          Disables transformation of string literals, which being matched by
          passed RegExp patterns.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts">    {
        <span class="hljs-attribute">reservedStrings</span>: [
            <span class="hljs-string">'react-native'</span>,
            <span class="hljs-string">'\.\/src\/test'</span>,
            <span class="hljs-string">'some-string_\d'</span>
        ]
    }
</code></pre>
        <h3 id="-rotatestringarray-"><code>rotateStringArray</code></h3>
        <p>Type: <code>boolean</code> Default: <code>true</code></p>
        <h5 id="-warning-stringarray-stringarray-must-be-enabled">
          :warning: <a href="#stringarray"><code>stringArray</code></a> must be
          enabled
        </h5>
        <p>
          Shift the <code>stringArray</code> array by a fixed and random
          (generated at the code obfuscation) places. This makes it harder to
          match the order of the removed strings to their original place.
        </p>
        <h3 id="-seed-"><code>seed</code></h3>
        <p>Type: <code>string|number</code> Default: <code>0</code></p>
        <p>
          This option sets seed for random generator. This is useful for
          creating repeatable results.
        </p>
        <p>
          If seed is <code>0</code> - random generator will work without seed.
        </p>
        <h3 id="-selfdefending-"><code>selfDefending</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <h5
          id="-warning-don-t-change-obfuscated-code-in-any-way-after-obfuscation-with-this-option-because-any-change-like-uglifying-of-code-can-trigger-self-defending-and-code-wont-work-anymore-"
        >
          :warning: Don&#39;t change obfuscated code in any way after
          obfuscation with this option, because any change like uglifying of
          code can trigger self defending and code wont work anymore!
        </h5>
        <h5 id="-warning-this-option-forcibly-sets-compact-value-to-true-">
          :warning: This option forcibly sets <code>compact</code> value to
          <code>true</code>
        </h5>
        <p>
          This option makes the output code resilient against formatting and
          variable renaming. If one tries to use a JavaScript beautifier on the
          obfuscated code, the code won&#39;t work anymore, making it harder to
          understand and modify it.
        </p>
        <h3 id="-shufflestringarray-"><code>shuffleStringArray</code></h3>
        <p>Type: <code>boolean</code> Default: <code>true</code></p>
        <h5 id="-warning-stringarray-stringarray-must-be-enabled">
          :warning: <a href="#stringarray"><code>stringArray</code></a> must be
          enabled
        </h5>
        <p>Randomly shuffles the <code>stringArray</code> array items.</p>
        <h3 id="-simplify-"><code>simplify</code></h3>
        <p>Type: <code>boolean</code> Default: <code>true</code></p>
        <p>Enables additional code obfuscation through simplification.</p>
        <h5
          id="-warning-in-future-releases-obfuscation-of-boolean-literals-true-will-be-moved-under-this-option-"
        >
          :warning: in future releases obfuscation of
          <code>boolean</code> literals (<code>true</code> =&gt;
          <code>!![]</code>) will be moved under this option.
        </h5>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// input</span>
<span class="hljs-keyword">if</span> (condition1) {
    <span class="hljs-keyword">const</span> foo = <span class="hljs-number">1</span>;
    <span class="hljs-keyword">const</span> bar = <span class="hljs-number">2</span>;

    <span class="hljs-built_in">console</span>.log(foo);

    <span class="hljs-keyword">return</span> bar;
} <span class="hljs-keyword">else</span> <span class="hljs-keyword">if</span> (condition2) {
    <span class="hljs-built_in">console</span>.log(<span class="hljs-number">1</span>);
    <span class="hljs-built_in">console</span>.log(<span class="hljs-number">2</span>);
    <span class="hljs-built_in">console</span>.log(<span class="hljs-number">3</span>);

    <span class="hljs-keyword">return</span> <span class="hljs-number">4</span>;
} <span class="hljs-keyword">else</span> {
    <span class="hljs-keyword">return</span> <span class="hljs-number">5</span>;
}

<span class="hljs-comment">// output</span>
<span class="hljs-keyword">if</span> (condition1) {
    <span class="hljs-keyword">const</span> foo = <span class="hljs-number">0x1</span>, bar = <span class="hljs-number">0x2</span>;
    <span class="hljs-keyword">return</span> <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](foo), bar;
} <span class="hljs-keyword">else</span>
    <span class="hljs-keyword">return</span> condition2 ? (<span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x1</span>), <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x2</span>), <span class="hljs-built_in">console</span>[<span class="hljs-string">'log'</span>](<span class="hljs-number">0x3</span>), <span class="hljs-number">0x4</span>) : <span class="hljs-number">0x5</span>;
</code></pre>
        <h3 id="-sourcemap-"><code>sourceMap</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <p>Enables source map generation for obfuscated code.</p>
        <p>
          Source maps can be useful to help you debug your obfuscated JavaScript
          source code. If you want or need to debug in production, you can
          upload the separate source map file to a secret location and then
          point your browser there.
        </p>
        <h3 id="-sourcemapbaseurl-"><code>sourceMapBaseUrl</code></h3>
        <p>Type: <code>string</code> Default: ``</p>
        <p>
          Sets base url to the source map import url when
          <a href="#sourcemapmode"
            ><code>sourceMapMode: &#39;separate&#39;</code></a
          >.
        </p>
        <p>CLI example:</p>
        <pre><code>javascript-obfuscator <span class="hljs-selector-tag">input</span><span class="hljs-selector-class">.js</span> --output out<span class="hljs-selector-class">.js</span> --source-map true --source-map-base-url <span class="hljs-string">'http://localhost:9000'</span>
</code></pre>
        <p>Result:</p>
        <pre><code><span class="hljs-regexp">//</span><span class="hljs-comment"># sourceMappingURL=http://localhost:9000/out.js.map</span>
</code></pre>
        <h3 id="-sourcemapfilename-"><code>sourceMapFileName</code></h3>
        <p>Type: <code>string</code> Default: ``</p>
        <p>
          Sets file name for output source map when
          <code>sourceMapMode: &#39;separate&#39;</code>.
        </p>
        <p>CLI example:</p>
        <pre><code>javascript-obfuscator <span class="hljs-built_in">input</span>.js --output out.js --<span class="hljs-keyword">source</span>-<span class="hljs-keyword">map</span> true --<span class="hljs-keyword">source</span>-<span class="hljs-keyword">map</span>-base-url <span class="hljs-string">'http://localhost:9000'</span> --<span class="hljs-keyword">source</span>-<span class="hljs-keyword">map</span>-<span class="hljs-keyword">file</span>-name example
</code></pre>
        <p>Result:</p>
        <pre><code><span class="hljs-regexp">//</span><span class="hljs-comment"># sourceMappingURL=http://localhost:9000/example.js.map</span>
</code></pre>
        <h3 id="-sourcemapmode-"><code>sourceMapMode</code></h3>
        <p>Type: <code>string</code> Default: <code>separate</code></p>
        <p>Specifies source map generation mode:</p>
        <ul>
          <li>
            <code>inline</code> - add source map at the end of each .js files;
          </li>
          <li>
            <code>separate</code> - generates corresponding &#39;.map&#39; file
            with source map. In case you run obfuscator through CLI - adds link
            to source map file to the end of file with obfuscated code
            <code>//# sourceMappingUrl=file.js.map</code>.
          </li>
        </ul>
        <h3 id="-splitstrings-"><code>splitStrings</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <p>
          Splits literal strings into chunks with length of
          <a href="#splitstringschunklength"
            ><code>splitStringsChunkLength</code></a
          >
          option value.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// input</span>
(<span class="hljs-function"><span class="hljs-keyword">function</span><span class="hljs-params">()</span></span>{
    <span class="hljs-keyword">var</span> test = <span class="hljs-string">'abcdefg'</span>;
})();

<span class="hljs-comment">// output</span>
(<span class="hljs-function"><span class="hljs-keyword">function</span><span class="hljs-params">()</span></span>{
    <span class="hljs-keyword">var</span> _0x5a21 = <span class="hljs-string">'ab'</span> + <span class="hljs-string">'cd'</span> + <span class="hljs-string">'ef'</span> + <span class="hljs-string">'g'</span>;
})();
</code></pre>
        <h3 id="-splitstringschunklength-">
          <code>splitStringsChunkLength</code>
        </h3>
        <p>Type: <code>number</code> Default: <code>10</code></p>
        <p>
          Sets chunk length of
          <a href="#splitstrings"><code>splitStrings</code></a> option.
        </p>
        <h3 id="-stringarray-"><code>stringArray</code></h3>
        <p>Type: <code>boolean</code> Default: <code>true</code></p>
        <p>
          Removes string literals and place them in a special array. For
          instance, the string <code>&quot;Hello World&quot;</code> in
          <code>var m = &quot;Hello World&quot;;</code> will be replaced with
          something like <code>var m = _0x12c456[0x1];</code>
        </p>
        <h3 id="-stringarrayencoding-"><code>stringArrayEncoding</code></h3>
        <p>Type: <code>string[]</code> Default: <code>[]</code></p>
        <h5 id="-warning-stringarray-option-must-be-enabled">
          :warning: <code>stringArray</code> option must be enabled
        </h5>
        <p>This option can slow down your script.</p>
        <p>
          Encode all string literals of the
          <a href="#stringarray"><code>stringArray</code></a> using
          <code>base64</code> or <code>rc4</code> and inserts a special code
          that used to decode it back at runtime.
        </p>
        <p>
          Each <code>stringArray</code> value will be encoded by the randomly
          picked encoding from the passed list. This makes possible to use
          multiple encodings.
        </p>
        <p>Available values:</p>
        <ul>
          <li>
            <code>&#39;none&#39;</code> (<code>boolean</code>): doesn&#39;t
            encode <code>stringArray</code> value
          </li>
          <li>
            <code>&#39;base64&#39;</code> (<code>string</code>): encodes
            <code>stringArray</code> value using <code>base64</code>
          </li>
          <li>
            <code>&#39;rc4&#39;</code> (<code>string</code>): encodes
            <code>stringArray</code> value using <code>rc4</code>.
            <strong
              >About 30-50% slower than <code>base64</code>, but more harder to
              get initial values.</strong
            >
            It&#39;s recommended to disable
            <a href="#unicodeescapesequence"
              ><code>unicodeEscapeSequence</code></a
            >
            option when using <code>rc4</code> encoding to prevent very large
            size of obfuscated code.
          </li>
        </ul>
        <p>
          For example with the following option values some
          <code>stringArray</code> value won&#39;t be encoded, and some values
          will be encoded with <code>base64</code> and
          <code>rc4</code> encoding:
        </p>
        <pre><code class="lang-ts"><span class="hljs-string">stringArrayEncoding:</span> [
    <span class="hljs-string">'none'</span>,
    <span class="hljs-string">'base64'</span>,
    <span class="hljs-string">'rc4'</span>
]
</code></pre>
        <h3 id="-stringarrayindexestype-">
          <code>stringArrayIndexesType</code>
        </h3>
        <p>
          Type: <code>string[]</code> Default:
          <code>[&#39;hexadecimal-number&#39;]</code>
        </p>
        <h5 id="-warning-stringarray-option-must-be-enabled">
          :warning: <code>stringArray</code> option must be enabled
        </h5>
        <p>Allows to control the type of string array call indexes.</p>
        <p>
          Each <code>stringArray</code> call index will be transformed by the
          randomly picked type from the passed list. This makes possible to use
          multiple types.
        </p>
        <p>Available values:</p>
        <ul>
          <li>
            <code>&#39;hexadecimal-number&#39;</code> (<code>default</code>):
            transforms string array call indexes as hexadecimal numbers
          </li>
          <li>
            <code>&#39;hexadecimal-numeric-string&#39;</code>: transforms string
            array call indexes as hexadecimal numeric string
          </li>
        </ul>
        <p>
          Before <code>2.9.0</code> release
          <code>javascript-obfuscator</code> transformed all string array call
          indexes with <code>hexadecimal-numeric-string</code> type. This makes
          some manual deobfuscation slightly harder but it allows easy detection
          of these calls by automatic deobfuscators.
        </p>
        <p>
          The new <code>hexadecimal-number</code> type approaches to make harder
          auto-detect of string array call patterns in the code.
        </p>
        <p>More types will be added in the future.</p>
        <h3 id="-stringarrayindexshift-"><code>stringArrayIndexShift</code></h3>
        <p>Type: <code>boolean</code> Default: <code>true</code></p>
        <h5 id="-warning-stringarray-option-must-be-enabled">
          :warning: <code>stringArray</code> option must be enabled
        </h5>
        <p>Enables additional index shift for all string array calls</p>
        <h3 id="-stringarraywrapperscount-">
          <code>stringArrayWrappersCount</code>
        </h3>
        <p>Type: <code>number</code> Default: <code>1</code></p>
        <h5 id="-warning-stringarray-stringarray-option-must-be-enabled">
          :warning: <a href="#stringarray"><code>stringArray</code></a> option
          must be enabled
        </h5>
        <p>
          Sets the count of wrappers for the <code>string array</code> inside
          each root or function scope. The actual count of wrappers inside each
          scope is limited by a count of <code>literal</code> nodes within this
          scope.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// Input</span>
<span class="hljs-keyword">const</span> foo = <span class="hljs-string">'foo'</span>;
<span class="hljs-keyword">const</span> bar = <span class="hljs-string">'bar'</span>;

<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">test</span> (<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">const</span> baz = <span class="hljs-string">'baz'</span>;
    <span class="hljs-keyword">const</span> bark = <span class="hljs-string">'bark'</span>;
    <span class="hljs-keyword">const</span> hawk = <span class="hljs-string">'hawk'</span>;
}

<span class="hljs-keyword">const</span> eagle = <span class="hljs-string">'eagle'</span>;

<span class="hljs-comment">// Output, stringArrayWrappersCount: 5</span>
<span class="hljs-keyword">const</span> _0x3018 = [
    <span class="hljs-string">'foo'</span>,
    <span class="hljs-string">'bar'</span>,
    <span class="hljs-string">'baz'</span>,
    <span class="hljs-string">'bark'</span>,
    <span class="hljs-string">'hawk'</span>,
    <span class="hljs-string">'eagle'</span>
];
<span class="hljs-keyword">const</span> _0x380f = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">_0x30182a, _0x380f29</span>) </span>{
    _0x30182a = _0x30182a - <span class="hljs-number">0x0</span>;
    <span class="hljs-keyword">let</span> _0x4e002c = _0x3018[_0x30182a];
    <span class="hljs-keyword">return</span> _0x4e002c;
};
<span class="hljs-keyword">const</span> _0xe4db7c = _0x380f;
<span class="hljs-keyword">const</span> _0x26ca42 = _0x380f;
<span class="hljs-keyword">const</span> _0x58c610 = _0x380f;
<span class="hljs-keyword">const</span> foo = _0x58c610(<span class="hljs-string">'0x0'</span>);
<span class="hljs-keyword">const</span> bar = _0x26ca42(<span class="hljs-string">'0x1'</span>);
<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">test</span>(<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">const</span> _0x500eda = _0x380f;
    <span class="hljs-keyword">const</span> _0x1d1760 = _0x380f;
    <span class="hljs-keyword">const</span> _0x4ca8b0 = _0x380f;
    <span class="hljs-keyword">const</span> _0x4e002c = _0x4ca8b0(<span class="hljs-string">'0x2'</span>);
    <span class="hljs-keyword">const</span> _0x573b1c = _0x1d1760(<span class="hljs-string">'0x3'</span>);
    <span class="hljs-keyword">const</span> _0x1fb6ef = _0x500eda(<span class="hljs-string">'0x4'</span>);
}
<span class="hljs-keyword">const</span> eagle = _0x26ca42(<span class="hljs-string">'0x5'</span>);
</code></pre>
        <h3 id="-stringarraywrapperschainedcalls-">
          <code>stringArrayWrappersChainedCalls</code>
        </h3>
        <p>Type: <code>boolean</code> Default: <code>true</code></p>
        <h5
          id="-warning-stringarray-stringarray-and-stringarraywrapperscount-stringarraywrapperscount-options-must-be-enabled"
        >
          :warning: <a href="#stringarray"><code>stringArray</code></a> and
          <a href="#stringarraywrapperscount"
            ><code>stringArrayWrappersCount</code></a
          >
          options must be enabled
        </h5>
        <p>
          Enables the chained calls between <code>string array</code> wrappers.
        </p>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// Input</span>
<span class="hljs-keyword">const</span> foo = <span class="hljs-string">'foo'</span>;
<span class="hljs-keyword">const</span> bar = <span class="hljs-string">'bar'</span>;

<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">test</span> (<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">const</span> baz = <span class="hljs-string">'baz'</span>;
    <span class="hljs-keyword">const</span> bark = <span class="hljs-string">'bark'</span>;

    <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">test1</span>(<span class="hljs-params"></span>) </span>{
        <span class="hljs-keyword">const</span> hawk = <span class="hljs-string">'hawk'</span>;
        <span class="hljs-keyword">const</span> eagle = <span class="hljs-string">'eagle'</span>;
    } 
}

<span class="hljs-comment">// Output, stringArrayWrappersCount: 5, stringArrayWrappersChainedCalls: true</span>
<span class="hljs-keyword">const</span> _0x4714 = [
    <span class="hljs-string">'foo'</span>,
    <span class="hljs-string">'bar'</span>,
    <span class="hljs-string">'baz'</span>,
    <span class="hljs-string">'bark'</span>,
    <span class="hljs-string">'hawk'</span>,
    <span class="hljs-string">'eagle'</span>
];
<span class="hljs-keyword">const</span> _0x2bdb = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">_0x471439, _0x2bdb71</span>) </span>{
    _0x471439 = _0x471439 - <span class="hljs-number">0x0</span>;
    <span class="hljs-keyword">let</span> _0x6e47e6 = _0x4714[_0x471439];
    <span class="hljs-keyword">return</span> _0x6e47e6;
};
<span class="hljs-keyword">const</span> _0x1c3d52 = _0x2bdb;
<span class="hljs-keyword">const</span> _0xd81c2a = _0x2bdb;
<span class="hljs-keyword">const</span> foo = _0xd81c2a(<span class="hljs-string">'0x0'</span>);
<span class="hljs-keyword">const</span> bar = _0x1c3d52(<span class="hljs-string">'0x1'</span>);
<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">test</span>(<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">const</span> _0x21a0b4 = _0x1c3d52;
    <span class="hljs-keyword">const</span> _0x12842d = _0xd81c2a;
    <span class="hljs-keyword">const</span> _0x6e47e6 = _0x12842d(<span class="hljs-string">'0x2'</span>);
    <span class="hljs-keyword">const</span> _0x4f3aef = _0x12842d(<span class="hljs-string">'0x3'</span>);
    <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">_0x40f1dc</span>(<span class="hljs-params"></span>) </span>{
        <span class="hljs-keyword">const</span> _0x468540 = _0x12842d;
        <span class="hljs-keyword">const</span> _0x1f4b05 = _0x21a0b4;
        <span class="hljs-keyword">const</span> _0x40a980 = _0x1f4b05(<span class="hljs-string">'0x4'</span>);
        <span class="hljs-keyword">const</span> _0x4d1285 = _0x468540(<span class="hljs-string">'0x5'</span>);
    }
}
</code></pre>
        <h3 id="-stringarraywrappersparametersmaxcount-">
          <code>stringArrayWrappersParametersMaxCount</code>
        </h3>
        <p>Type: <code>number</code> Default: <code>2</code></p>
        <h5 id="-warning-stringarray-stringarray-option-must-be-enabled">
          :warning: <a href="#stringarray"><code>stringArray</code></a> option
          must be enabled
        </h5>
        <h5
          id="-warning-currently-this-option-affects-only-wrappers-added-by-stringarraywrapperstype-stringarraywrapperstype-function-option-value"
        >
          :warning: Currently this option affects only wrappers added by
          <a href="#stringarraywrapperstype"
            ><code>stringArrayWrappersType</code></a
          >
          <code>function</code> option value
        </h5>
        <p>
          Allows to control the maximum number of string array wrappers
          parameters. Default and minimum value is <code>2</code>. Recommended
          value between <code>2</code> and <code>5</code>.
        </p>
        <h3 id="-stringarraywrapperstype-">
          <code>stringArrayWrappersType</code>
        </h3>
        <p>Type: <code>string</code> Default: <code>variable</code></p>
        <h5
          id="-warning-stringarray-stringarray-and-stringarraywrapperscount-stringarraywrapperscount-options-must-be-enabled"
        >
          :warning: <a href="#stringarray"><code>stringArray</code></a> and
          <a href="#stringarraywrapperscount"
            ><code>stringArrayWrappersCount</code></a
          >
          options must be enabled
        </h5>
        <p>
          Allows to select a type of the wrappers that are appending by the
          <code>stringArrayWrappersCount</code> option.
        </p>
        <p>Available values:</p>
        <ul>
          <li>
            <code>&#39;variable&#39;</code>: appends variable wrappers. Fast
            performance.
          </li>
          <li>
            <code>&#39;function&#39;</code>: appends function wrappers. Slower
            performance than with <code>variable</code> but provides more strict
            obfuscation
          </li>
        </ul>
        <p>
          Highly recommended to use <code>function</code> wrappers for higher
          obfuscation when a performance loss doesn&#39;t have a high impact on
          an obfuscated application.
        </p>
        <p>Example of the <code>&#39;function&#39;</code> option value:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// input</span>
<span class="hljs-keyword">const</span> foo = <span class="hljs-string">'foo'</span>;

<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">test</span> (<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">const</span> bar = <span class="hljs-string">'bar'</span>;
}

<span class="hljs-comment">// output</span>
<span class="hljs-keyword">const</span> a = [
    <span class="hljs-string">'foo'</span>,
    <span class="hljs-string">'bar'</span>
];
<span class="hljs-keyword">const</span> b = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">c, d</span>) </span>{
    c = c - <span class="hljs-number">0x0</span>;
    <span class="hljs-keyword">let</span> e = a[c];
    <span class="hljs-keyword">return</span> e;
};
<span class="hljs-keyword">const</span> d = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">c, f</span>) </span>{
    <span class="hljs-keyword">return</span> b(c - <span class="hljs-string">'0x372'</span>, f);
};
<span class="hljs-keyword">const</span> foo = d(<span class="hljs-string">'0x372'</span>);
<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">test</span>(<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">const</span> e = <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">c, f</span>) </span>{
        <span class="hljs-keyword">return</span> d(c - -<span class="hljs-string">'0x260'</span>, f);
    };
    <span class="hljs-keyword">const</span> c = e(<span class="hljs-string">'0x113'</span>);
}
</code></pre>
        <h3 id="-stringarraythreshold-"><code>stringArrayThreshold</code></h3>
        <p>
          Type: <code>number</code> Default: <code>0.8</code> Min:
          <code>0</code> Max: <code>1</code>
        </p>
        <h5 id="-warning-stringarray-stringarray-option-must-be-enabled">
          :warning: <a href="#stringarray"><code>stringArray</code></a> option
          must be enabled
        </h5>
        <p>
          You can use this setting to adjust the probability (from 0 to 1) that
          a string literal will be inserted into the <code>stringArray</code>.
        </p>
        <p>
          This setting is especially useful for large code size because it
          repeatedly calls to the <code>string array</code> and can slow down
          your code.
        </p>
        <p>
          <code>stringArrayThreshold: 0</code> equals to
          <code>stringArray: false</code>.
        </p>
        <h3 id="-target-"><code>target</code></h3>
        <p>Type: <code>string</code> Default: <code>browser</code></p>
        <p>Allows to set target environment for obfuscated code.</p>
        <p>Available values:</p>
        <ul>
          <li><code>browser</code>;</li>
          <li><code>browser-no-eval</code>;</li>
          <li><code>node</code>.</li>
        </ul>
        <p>
          Currently output code for <code>browser</code> and
          <code>node</code> targets is identical, but some browser-specific
          options are not allowed to use with <code>node</code> target. Output
          code for <code>browser-no-eval</code> target is not using
          <code>eval</code>.
        </p>
        <h3 id="-transformobjectkeys-"><code>transformObjectKeys</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <p>Enables transformation of object keys.</p>
        <p>Example:</p>
        <pre><code class="lang-ts"><span class="hljs-comment">// input</span>
(<span class="hljs-function"><span class="hljs-keyword">function</span><span class="hljs-params">()</span></span>{
    <span class="hljs-keyword">var</span> object = {
        foo: <span class="hljs-string">'test1'</span>,
        bar: {
            baz: <span class="hljs-string">'test2'</span>
        }
    };
})();

<span class="hljs-comment">// output</span>
<span class="hljs-keyword">var</span> _0x2fae = [
    <span class="hljs-string">'baz'</span>,
    <span class="hljs-string">'test2'</span>,
    <span class="hljs-string">'foo'</span>,
    <span class="hljs-string">'test1'</span>,
    <span class="hljs-string">'bar'</span>
];
<span class="hljs-keyword">var</span> _0x377c = <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-params">(_0x1fbd3f, _0x59c72f)</span> </span>{
    _0x1fbd3f = _0x1fbd3f - <span class="hljs-number">0x0</span>;
    <span class="hljs-keyword">var</span> _0x14fada = _0x2fae[_0x1fbd3f];
    <span class="hljs-keyword">return</span> _0x14fada;
};
(<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-params">()</span> </span>{
    <span class="hljs-keyword">var</span> _0x8a12db = {};
    _0x8a12db[_0x377c(<span class="hljs-string">'0x0'</span>)] = _0x377c(<span class="hljs-string">'0x1'</span>);
    <span class="hljs-keyword">var</span> _0xc75419 = {};
    _0xc75419[_0x377c(<span class="hljs-string">'0x2'</span>)] = _0x377c(<span class="hljs-string">'0x3'</span>);
    _0xc75419[_0x377c(<span class="hljs-string">'0x4'</span>)] = _0x8a12db;
    <span class="hljs-keyword">var</span> _0x191393 = _0xc75419;
}());
</code></pre>
        <h3 id="-unicodeescapesequence-"><code>unicodeEscapeSequence</code></h3>
        <p>Type: <code>boolean</code> Default: <code>false</code></p>
        <p>
          Allows to enable/disable string conversion to unicode escape sequence.
        </p>
        <p>
          Unicode escape sequence increases code size greatly and strings easily
          can be reverted to their original view. Recommended to enable this
          option only for small source code.
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "Documentation",
};
</script>