import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false
Vue.use(VueClipboard)

new Vue({
  VueClipboard,
  vuetify,
  VueCodemirror,
  render: h => h(App)
}).$mount('#app')