<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-img :src="require('../assets/jsobfuscator-logo-with-text.png')" class="my-3" contain height="200" />
            </v-col>

            <v-col class="mb-4">
                <p class="subheading font-weight-regular">
                    Prevent people from stealing your JavaScript code directly from the browser. It is specially useful for games, template developers that sell their work on ThemeForest or related template marketplaces. Show your work to the client without giving it away. This tool allows you to simply paste your code in the editor or upload the file from your device.
                    <br>
                    The obfuscator runs in your own machine through a JavaScript worker, so if there's a lot of code to handle, the user interface won't freeze. Your code stays on your machine, there's no server-side processing.
                </p>

                <a href="https://www.digitalocean.com/?refcode=b84458bbcb69&amp;utm_campaign=Referral_Invite&amp;utm_medium=Referral_Program&amp;utm_source=badge">
                    <img src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%202.svg" alt="DigitalOcean Referral Badge">
                </a>
            </v-col>

            <v-col class="mb-5" cols="12">
                <h2 class="headline font-weight-bold mb-3">
                    Important Links
                </h2>

                <v-row justify="center">
                    <a v-for="(link, i) in importantLinks" :key="i" :href="link.href" class="subheading mx-3"
                        target="_blank">
                        {{ link.text }}
                    </a>
                </v-row>
            </v-col>

            <v-col class="mb-5" cols="12">
                <h2 class="headline font-weight-bold mb-3">
                    Legal
                </h2>

                <v-row justify="center">
                    <a v-for="(eco, i) in legal" :key="i" :href="eco.href" class="subheading mx-3" target="_blank">
                        {{ eco.text }}
                    </a>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'Footer',

        data: () => ({
             
            importantLinks: [
                {
                    text: 'Documentation',
                    href: 'https://github.com/javascript-obfuscator/javascript-obfuscator',
                }
            ],
            legal: [
                {
                    text: 'Privacy Policy',
                    href: 'https://ourcodeworld.com/privacy-policy',
                }
            ],
        }),
    }
</script>