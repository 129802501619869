/*
let result = {};

for(let i = 0;i < a.length;i++)
{
    let item = a[i];
    result[item.key] = item.default;
}

console.log(result);
 

export default {
    "fields": [
        {
            "key": "compact",
            "type": "boolean",
            "default": true
        },
        {
            "key": "selfDefending",
            "type": "boolean",
            "default": false
        },
        {
            "key": "disableConsoleOutput",
            "type": "boolean",
            "default": false
        },
        {
            "key": "debugProtection",
            "type": "boolean",
            "default": false
        },
        {
            "key": "debugProtectionInterval",
            "type": "boolean",
            "default": false
        },
        {
            "key": "splitStrings",
            "type": "boolean",
            "default": false
        },
        {
            "key": "splitStringsChunkLength",
            "type": "number",
            "default": 10
        },
        {
            "key": "splitStringsChunkLengthEnabled",
            "type": "boolean",
            "default": false
        },
        {
            "key": "stringArray",
            "type": "boolean",
            "default": true
        },
        {
            "key": "rotateStringArray",
            "type": "boolean",
            "default": true
        },
        {
            "key": "rotateStringArrayEnabled",
            "type": "boolean",
            "default": true
        },
        {
            "key": "shuffleStringArray",
            "type": "boolean",
            "default": true
        },
        {
            "key": "shuffleStringArrayEnabled",
            "type": "boolean",
            "default": true
        },
        {
            "key": "simplify",
            "type": "boolean",
            "default": true
        },
        {
            "key": "stringArrayThreshold",
            "type": "number",
            "default": 0.75
        },
        {
            "key": "stringArrayThresholdEnabled",
            "type": "boolean",
            "default": true
        },
        {
            "key": "stringArrayIndexesType",
            "type": "select-multiple",
            "default": "hexadecimal-number",
            "values": {
                "hexadecimal-number": "Hexadecimal Number",
                "hexadecimal-numeric-string": "Hexadecimal Numeric String"
            }
        },
        {
            "key": "stringArrayIndexShift",
            "type": "boolean",
            "default": true
        },
        {
            "key": "stringArrayEncoding",
            "type": "select-multiple",
            "default": "",
            "values": {
                "none": "None",
                "base64": "Base64",
                "rc4": "RC4"
            }
        },
        {
            "key": "stringArrayEncodingEnabled",
            "type": "boolean",
            "default": true
        },
        {
            "key": "stringArrayWrappersCount",
            "type": "number",
            "default": 1
        },

        {
            "key": "stringArrayWrappersChainedCalls",
            "type": "boolean",
            "default": true
        },

        {
            "key": "stringArrayWrappersParametersMaxCount",
            "type": "number",
            "default": 2
        },
        {
            "key": "stringArrayWrappersType",
            "type": "select",
            "default": "variable",
            "values": {
                "function": "Function",
                "variable": "Variable" 
            }
        },
        {
            "key": "numbersToExpressions",
            "type": "boolean",
            "default": false
        },



        {
            "key": "sourceMap",
            "type": "boolean",
            "default": false
        },
        {
            "key": "sourceMapMode",
            "type": "select",
            "default": "separate",
            "values": {
                "inline": "Inline",
                "separate": "Separate" 
            }
        },
        {
            "key": "sourceMapBaseUrl",
            "type": "text",
            "default": ""
        },
        {
            "key": "sourceMapFileName",
            "type": "text",
            "default": ""
        },

        {
            "key": "domainLock",
            "type": "array",
            "default": ""
        },

        {
            "key": "domainLockEnabled",
            "type": "boolean",
            "default": true
        },

        {
            "key": "forceTransformStrings",
            "type": "array",
            "default": ""
        },
        {
            "key": "reservedNames",
            "type": "array",
            "default": ""
        },
        {
            "key": "reservedStrings",
            "type": "array",
            "default": ""
        },


        {
            "key": "seed",
            "type": "number",
            "default": 0
        },

        {
            "key": "controlFlowFlatteningThreshold",
            "type": "number",
            "default": 0.75
        },
        {
            "key": "controlFlowFlattening",
            "type": "boolean",
            "default": false
        },

        {
            "key": "deadCodeInjectionThreshold",
            "type": "number",
            "default": 0.4
        },
        {
            "key": "deadCodeInjection",
            "type": "boolean",
            "default": false
        },

        {
            "key": "unicodeEscapeSequence",
            "type": "boolean",
            "default": false
        },
        {
            "key": "renameGlobals",
            "type": "boolean",
            "default": false
        },
        {
            "key": "renameProperties",
            "type": "boolean",
            "default": false
        },
        {
            "key": "target",
            "type": "select",
            "default": "separate",
            "values": {
                "browser": "Browser",
                "browser-no-eval": "Browser No Eval",
                "node": "Node.js" 
            }
        },

        {
            "key": "identifierNamesGenerator",
            "type": "select",
            "default": "separate",
            "values": {
                "dictionary": "Inline",
                "hexadecimal": "Separate",
                "mangled": "Mangled",
                "mangled-shuffled": "Mangled Shuffle"
            }
        },

        {
            "key": "identifiersDictionary",
            "type": "array",
            "default": ""
        },

        {
            "key": "identifiersPrefix",
            "type": "text",
            "default": ""
        },

        {
            "key": "transformObjectKeys",
            "type": "boolean",
            "default": false
        },
        {
            "key": "ignoreRequireImports",
            "type": "boolean",
            "default": false
        },
    ]
};

*/


export default {
    "presets": {
        "default": {
            compact: true,
            controlFlowFlattening: false,
            deadCodeInjection: false,
            debugProtection: false,
            debugProtectionInterval: 0,
            disableConsoleOutput: false,
            identifierNamesGenerator: 'hexadecimal',
            log: false,
            numbersToExpressions: false,
            renameGlobals: false,
            rotateStringArray: true,
            selfDefending: false,
            shuffleStringArray: true,
            simplify: true,
            splitStrings: false,
            stringArray: true,
            stringArrayEncoding: [],
            stringArrayIndexShift: true,
            stringArrayWrappersCount: 1,
            stringArrayWrappersChainedCalls: true,
            stringArrayWrappersParametersMaxCount: 2,
            stringArrayWrappersType: 'variable',
            stringArrayThreshold: 0.75,
            unicodeEscapeSequence: false
        },
        "low": {
            compact: true,
            controlFlowFlattening: false,
            deadCodeInjection: false,
            debugProtection: false,
            debugProtectionInterval: 0,
            disableConsoleOutput: true,
            identifierNamesGenerator: 'hexadecimal',
            log: false,
            numbersToExpressions: false,
            renameGlobals: false,
            rotateStringArray: true,
            selfDefending: true,
            shuffleStringArray: true,
            simplify: true,
            splitStrings: false,
            stringArray: true,
            stringArrayEncoding: [],
            stringArrayIndexShift: true,
            stringArrayWrappersCount: 1,
            stringArrayWrappersChainedCalls: true,
            stringArrayWrappersParametersMaxCount: 2,
            stringArrayWrappersType: 'variable',
            stringArrayThreshold: 0.75,
            unicodeEscapeSequence: false
        },
        "medium": {
            compact: true,
            controlFlowFlattening: true,
            controlFlowFlatteningThreshold: 0.75,
            deadCodeInjection: true,
            deadCodeInjectionThreshold: 0.4,
            debugProtection: false,
            debugProtectionInterval: 0,
            disableConsoleOutput: true,
            identifierNamesGenerator: 'hexadecimal',
            log: false,
            numbersToExpressions: true,
            renameGlobals: false,
            rotateStringArray: true,
            selfDefending: true,
            shuffleStringArray: true,
            simplify: true,
            splitStrings: true,
            splitStringsChunkLength: 10,
            stringArray: true,
            stringArrayEncoding: ['base64'],
            stringArrayIndexShift: true,
            stringArrayWrappersCount: 2,
            stringArrayWrappersChainedCalls: true,
            stringArrayWrappersParametersMaxCount: 4,
            stringArrayWrappersType: 'function',
            stringArrayThreshold: 0.75,
            transformObjectKeys: true,
            unicodeEscapeSequence: false
        },
        "high": {
            compact: true,
            controlFlowFlattening: true,
            controlFlowFlatteningThreshold: 1,
            deadCodeInjection: true,
            deadCodeInjectionThreshold: 1,
            debugProtection: true,
            debugProtectionInterval: 0,
            disableConsoleOutput: true,
            identifierNamesGenerator: 'hexadecimal',
            log: false,
            numbersToExpressions: true,
            renameGlobals: false,
            rotateStringArray: true,
            selfDefending: true,
            shuffleStringArray: true,
            simplify: true,
            splitStrings: true,
            splitStringsChunkLength: 5,
            stringArray: true,
            stringArrayEncoding: ['rc4'],
            stringArrayIndexShift: true,
            stringArrayWrappersCount: 5,
            stringArrayWrappersChainedCalls: true,    
            stringArrayWrappersParametersMaxCount: 5,
            stringArrayWrappersType: 'function',
            stringArrayThreshold: 1,
            transformObjectKeys: true,
            unicodeEscapeSequence: false
        }
    },
    "compact": {
        "label": "Compact",
        "type": "boolean",
        "default": true
    },
    "selfDefending": {
        "label": "Self Defending",
        "type": "boolean",
        "default": false
    },
    "disableConsoleOutput": {
        "label": "Disable Console Output",
        "type": "boolean",
        "default": false
    },
    "debugProtection": {
        "label": "Debug Protection",
        "type": "boolean",
        "default": false
    },
    "debugProtectionInterval": {
        "label": "Debug Protection Interval",
        "type": "number",
        "default": 0
    },
    "splitStrings": {
        "label": "Split Strings",
        "type": "boolean",
        "default": false
    },
    "splitStringsChunkLength": {
        "label": "Split Strings Chunk Length",
        "type": "number",
        "default": 10
    },
    "splitStringsChunkLengthEnabled": {
        "key": "splitStringsChunkLengthEnabled",
        "type": "boolean",
        "default": false
    },
    "stringArray": {
        "label": "String Array",
        "type": "boolean",
        "default": true
    },
    "rotateStringArray": {
        "label": "Rotate String Array",
        "type": "boolean",
        "default": true
    },
    "rotateStringArrayEnabled": {
        "key": "rotateStringArrayEnabled",
        "type": "boolean",
        "default": true
    },
    "shuffleStringArray": {
        "key": "shuffleStringArray",
        "type": "boolean",
        "default": true
    },
    "shuffleStringArrayEnabled": {
        "label": "Shuffle String Array Enabled",
        "type": "boolean",
        "default": true
    },
    "simplify": {
        "label": "Simplify",
        "type": "boolean",
        "default": true
    },
    "stringArrayThreshold": {
        "label": "String Array Threshold",
        "type": "number",
        "default": 0.75
    },
    "stringArrayThresholdEnabled": {
        "key": "stringArrayThresholdEnabled",
        "type": "boolean",
        "default": true
    },
    "stringArrayIndexesType": {
        "label": "String Array Indexes Type",
        "type": "select-multiple",
        "default": ["hexadecimal-number"],
        "values": [
            "hexadecimal-number", 
            "hexadecimal-numeric-string"
        ]
    },
    "stringArrayIndexShift": {
        "label": "String Array Index Shift",
        "type": "boolean",
        "default": true
    },
    "stringArrayEncoding": {
        "label": "String Array Encoding",
        "type": "select-multiple",
        "default": "",
        "values": ["none", "base64", "rc4"]
    },
    "stringArrayEncodingEnabled": {
        "key": "stringArrayEncodingEnabled",
        "type": "boolean",
        "default": true
    },
    "stringArrayWrappersCount": {
        "label": "String Array Wrappers Count",
        "type": "number",
        "default": 1
    },
    "stringArrayWrappersChainedCalls": {
        "label": "String Array Wrappers Chained Calls",
        "type": "boolean",
        "default": true
    },
    "stringArrayWrappersParametersMaxCount": {
        "label": "String Array Wrappers Parameters Max Count",
        "type": "number",
        "default": 2
    },
    "stringArrayWrappersType": {
        "label": "String Array Wrappers Type",
        "type": "select",
        "default": "variable",
        "values": [
            "function", "variable"
        ]
    },
    "numbersToExpressions": {
        "label": "Numbers To Expressions",
        "type": "boolean",
        "default": false
    },
    "sourceMap": {
        "label": "Enable Source Map",
        "type": "boolean",
        "default": false
    },
    "sourceMapMode": {
        "label": "Source Map Node",
        "type": "select",
        "default": "separate",
        "values": [
            "inline", "separate"
        ]
    },
    "sourceMapBaseUrl": {
        "label": "Source Map Base URL",
        "type": "text",
        "default": ""
    },
    "sourceMapFileName": {
        "label": "Source Map File Name",
        "type": "text",
        "default": ""
    },
    "domainLock": {
        "label": "Domain Lock",
        "type": "array",
        "default": ""
    },
    "domainLockEnabled": {
        "label": "domainLockEnabled",
        "type": "boolean",
        "default": true
    },
    "forceTransformStrings": {
        "label": "Force Transform Strings (a string or Regular Expression)",
        "type": "array",
        "default": ""
    },
    "reservedNames": {
        "label": "Reserved Names (a string or Regular Expression)",
        "type": "array",
        "default": ""
    },
    "reservedStrings": {
        "label": "Reserved Strings (a string or Regular Expression)",
        "type": "array",
        "default": ""
    },
    "seed": {
        "label": "Seed",
        "type": "number",
        "default": 0
    },
    "controlFlowFlatteningThreshold": {
        "label": "Control Flow Flattening Threshold",
        "type": "number",
        "default": 0.75
    },
    "controlFlowFlattening": {
        "label": "Control Flow Flattening",
        "type": "boolean",
        "default": false
    },
    "deadCodeInjectionThreshold": {
        "label": "Dead Code Injection Threshold",
        "type": "number",
        "default": 0.4
    },
    "deadCodeInjection": {
        "label": "Dead Code Injection",
        "type": "boolean",
        "default": false
    },
    "unicodeEscapeSequence": {
        "label": "Unicode Escape Sequence",
        "type": "boolean",
        "default": false
    },
    "renameGlobals": {
        "label": "Rename Globals",
        "type": "boolean",
        "default": false
    },
    "renameProperties": {
        "label": "Rename Properties",
        "type": "boolean",
        "default": false
    },
    "target": {
        "label": "Target",
        "type": "select",
        "default": "browser",
        "values": [
            "browser",
            "browser-no-eval",
            "node"
        ]
    },
    "identifierNamesGenerator": {
        "label": "Identifier Names Generator",
        "type": "select",
        "default": "hexadecimal",
        "values": [
            "dictionary", "hexadecimal", "mangled", "mangled-shuffled"
        ]
    },
    "identifiersDictionary": {
        "label": "Identifiers Dictionary",
        "type": "array",
        "default": ""
    },
    "identifiersPrefix": {
        "label": "Identifiers Prefix",
        "type": "text",
        "default": ""
    },
    "transformObjectKeys": {
        "label": "Transform Object Keys",
        "type": "boolean",
        "default": false
    },
    "ignoreRequireImports": {
        "label": "Ignore Require Imports",
        "type": "boolean",
        "default": false
    }
}