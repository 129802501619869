<template>
    <v-app>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                <v-img alt="Vuetify Logo" 
                    class="shrink mr-2" 
                    contain
                    src="./assets/jsobfuscator-logo-with-text.png" transition="scale-transition"
                    width="150" 
                />
            </div>

            <v-spacer></v-spacer>

            <v-btn href="https://ourcodeworld.com/contact" target="_blank" text>
                <span class="mr-2">
                    Contact us
                </span>
            </v-btn>
        </v-app-bar>

        <v-main>
            <Application />
            <Footer />
        </v-main>
    </v-app>
</template>

<script>
    import Application from './components/Application';
    
    import Footer from './components/Footer';

    export default {
        name: 'App',
        components: {
            Application, 
            Footer
        },
        data: () => ({
            //
        }),
    };
</script>